import * as Sentry from "@sentry/remix";
import { dequal } from "dequal";
import { useEffect, useRef } from "react";

export function useAnalytics() {
  return {
    setUser: Sentry.setUser,
    clearUser: () => Sentry.setUser(null),
  };
}

export function useAnalyticsSetUser(user?: Sentry.User) {
  const { setUser } = useAnalytics();
  const prevUser = useRef<Sentry.User | null>();
  useEffect(() => {
    if (!dequal(prevUser.current, user)) {
      prevUser.current = user;
      setUser(user ?? null);
    }
  }, [prevUser, setUser, user]);
}
